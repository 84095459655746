<template>
  <v-container fluid>
    <!-- Título y descripción -->
    <v-row align="center" class="px-10 pt-5">
      <h2 class="font-weight-bold" style="font-size: 24px">Nuevo producto</h2>
      <p>Ingresa la información del producto</p>
    </v-row>

    <!-- Formulario -->
    <v-form ref="form" v-model="valid">
      <!-- Información General -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Información general
          </v-card-title>
          <v-card-text>
            <pat-input
              label="Nombre del producto"
              placeholder="Ej. Jarabe"
              v-model="product.productName"
              :rules="[rules.required]"
              class="mb-5"
            />

            <!-- Marca -->
            <pat-input
              label="Marca"
              placeholder="(Opcional)"
              v-model="product.brand"
              class="mb-5"
            />

            <!-- Categoría -->
            <div class="mb-4">
              <label class="d-flex align-center font-weight-bold mb-3">
                Categoría
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Forma de organizar y agrupar los productos de tu negocio en
                    secciones específicas. Ejemplo: "Medicamentos".</span
                  >
                </v-tooltip>
              </label>
              <pat-select
                placeholder="Selecciona una categoría"
                :items="categoriesFilter"
                v-model="product.selectedCategory"
                :rules="[rules.required]"
              />
            </div>

            <!-- Subcategoría -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Subcategoría
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Clasifica los productos en grupos aún más pequeños y
                    específicos. Ejemplo: la categoría principal es
                    "Medicamentos". Dentro puedes encontrar subcategorías más
                    como "Dolor de estómago".</span
                  >
                </v-tooltip>
              </label>
              <pat-select
                placeholder="Selecciona subcategoría (Opcional)"
                :items="subcategories"
                v-model="product.selectedSubcategory"
              />
            </div>

            <!-- Producto controlado -->
            <v-radio-group v-model="product.controlledProduct" class="">
              <v-radio
                label="Producto controlado o requiere receta médica"
                value="true"
              />
            </v-radio-group>

            <!-- Descripción -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Descripción
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Proporciona información detallada sobre el producto.
                    Detalla las características, beneficios, modo de uso,
                    precauciones y cualquier otra información relevante para el
                    consumidor. En el caso de medicamentos controlados o que
                    requieren receta no debe indicar ninguna descripción.</span
                  >
                </v-tooltip>
              </label>
              <pat-input
                textarea
                placeholder="Agrega información adicional a tu producto (Opcional)"
                v-model="product.description"
              />
            </div>

            <!-- Proveedor o fabricante -->
            <pat-input
              label="Proveedor o fabricante"
              placeholder="(Opcional)"
              v-model="product.supplier"
              class="mb-5"
            />

            <!-- Unidad de venta -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Unidad de venta
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Es la forma en la que se vende el producto o mercancía al
                    cliente. Ejemplo: Un frasco con 120 cápsulas, la unidad de
                    venta es el frasco.</span
                  >
                </v-tooltip>
              </label>
              <pat-select
                placeholder="Selecciona unidad de venta"
                :items="unitsOfSale"
                v-model="product.selectedUnitOfSale"
                :rules="[rules.required]"
              />
            </div>

            <!-- Contenido -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Contenido
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Cantidad de producto que contiene la unidad de venta.
                    Ejemplo: Frasco con 120 cápsulas. El contenido es 120
                    cápsulas.</span
                  >
                </v-tooltip>
              </label>
              <pat-input placeholder="(Opcional)" v-model="product.content" />
            </div>

            <!-- Unidad de medida -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Unidad de medida
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >La unidad de medida del producto o servicio de venta.
                    Ejemplo: Frasco con 120 cápsulas de 5 gramos cada una donde
                    la unidad de medida son 5 gramos.</span
                  >
                </v-tooltip>
              </label>
              <pat-select
                placeholder="Selecciona unidad de medida"
                :items="unitsOfMeasurement"
                v-model="product.selectedUnitOfMeasurement"
                :rules="[rules.required]"
              />
            </div>

            <!-- Cantidad -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Cantidad
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Agrega la cantidad disponible (Opcional).</span>
                </v-tooltip>
              </label>
              <pat-input placeholder="(Opcional)" v-model="product.quantity" />
            </div>

            <!-- Código de barras -->
            <div class="mb-2">
              <label class="d-flex align-center font-weight-bold mb-3">
                Código de barras
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Registrar el código de barras te permitirá escanear
                    productos con tu celular. Para ingresarlo, escanea el código
                    de barras o digítalo.</span
                  >
                </v-tooltip>
              </label>
              <pat-input
                type="number"
                placeholder="0 000000 000000 (Opcional)"
                v-model="product.BarCode"
                appendIcon="mdi-barcode-scan"
                :messages="['Escanea el código de barras o digítalo']"
              />
            </div>

            <!-- SKU -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                SKU
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Código de uso interno que te permitirá identificar con
                    facilidad un producto. Se sugiere solo usarlo para aquellos
                    que no tienen un código de barras asignado. Ejemplo, para un
                    jarabe Pepto podrías crear su SKU: JAR-PEP-250ML.</span
                  >
                </v-tooltip>
              </label>
              <pat-input placeholder="(Opcional)" v-model="product.sku" />
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Especificaciones -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Especificaciones
          </v-card-title>
          <v-card-text>
            <!-- Lista de sustancias activas -->
            <div
              v-for="(substance, index) in product.activeSubstances"
              :key="index"
              class="mb-5"
            >
              <!-- Input de Sustancia Activa -->
              <pat-input
                class="mb-2"
                label="Sustancia Activa"
                placeholder="Naranja en polvo"
                v-model="substance.name"
                :rules="[rules.required]"
              >
                <template #append>
                  <v-icon
                    class="cursor-pointer"
                    @click="removeActiveSubstance(index)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </template>
              </pat-input>

              <!-- Concentración y Unidad -->
              <div class="d-flex justify-space-between">
                <div class="mr-2">
                  <pat-input
                    label="Concentración"
                    placeholder="500"
                    v-model="substance.concentration"
                    class="mt-1"
                    style="min-width: 100px"
                    :rules="[rules.required]"
                  />
                </div>
                <div>
                  <pat-select
                    label="Unidad"
                    :items="unityOptions"
                    placeholder="MG"
                    v-model="substance.unity"
                    class="mt-1"
                    style="min-width: 100px"
                    :rules="[rules.required]"
                  />
                </div>
              </div>
            </div>

            <!-- Opción "No Aplica" -->
            <v-radio-group v-model="product.noActiveSubstance">
              <v-radio
                label="No aplica"
                value="true"
                class="mt-2"
                hide-details
              />
            </v-radio-group>

            <!-- Botón para agregar sustancia activa -->
            <v-btn
              text
              color="primary"
              class="mt-2 d-flex align-center text-subtitle-1 font-weight-bold"
              @click="addActiveSubstance"
            >
              <v-icon
                left
                color="primary"
                class="x align-center"
                style="font-size: 24px"
              >
                mdi-plus
              </v-icon>
              <span class="text-decoration-underline"
                >Agregar Sustancia Activa</span
              >
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Carga de Imagen del Producto -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <div
            class="text-center d-flex flex-column align-center justify-center pa-3"
            style="
              background-color: #fff;
              border: 1px solid #000;
              height: 200px;
              width: 100%;
            "
          >
            <v-icon size="50" color="black">mdi-image-plus-outline</v-icon>
            <p class="mt-2 text-subtitle-2 grey--text">
              Da clic aquí para tomar una fotografía o subir un archivo
            </p>
          </div>
        </v-card>
      </v-col>

      <!-- Variantes -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Variantes
          </v-card-title>
          <v-card-text>
            <!-- Botón de agregar variante -->
            <v-btn
              text
              color="primary"
              class="mt-2 d-flex align-center text-subtitle-1 font-weight-bold"
              @click="addVariant"
            >
              <v-icon left color="primary">mdi-plus</v-icon>
              <span class="text-decoration-underline">Agregar Variante</span>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Impuestos -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Impuestos
          </v-card-title>
          <v-card-text>
            <!-- Radio button para impuestos -->
            <v-radio-group v-model="product.chargeTax">
              <v-radio
                label="Cobrar impuestos del producto"
                value="true"
                hide-details
              />
            </v-radio-group>

            <!-- Selectores de IVA e IEPS -->
            <div class="d-flex justify-space-between mt-2">
              <div class="mr-2">
                <pat-select
                  label="IVA"
                  :items="ivaOptions"
                  placeholder="Selecciona"
                  v-model="product.iva"
                  class="mt-1"
                  style="min-width: 100px"
                />
              </div>
              <div>
                <pat-select
                  label="IEPS"
                  :items="iepsOptions"
                  placeholder="Selecciona"
                  v-model="product.ieps"
                  class="mt-1"
                  style="min-width: 100px"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Facturación -->
      <v-col cols="12" md="12" class="d-flex">
        <v-card
          class="pa-4 bgcards rounded-xl"
          elevation="2"
          style="width: 100%"
        >
          <v-card-title class="text-h6 font-weight-bold">
            Facturación
          </v-card-title>
          <v-card-text>
            <!-- Clave SAT -->
            <div class="mb-5">
              <label class="d-flex align-center font-weight-bold mb-3">
                Clave SAT
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="ml-1"
                      style="font-size: 18px"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span
                    >Para generar facturas busca o ingresa el código
                    correspondiente al producto. Posteriormente te permitirá
                    facturar más fácil y rápido.</span
                  >
                </v-tooltip>
              </label>
              <pat-input
                placeholder="Busca por palabras clave de tu producto"
                v-model="product.satKey"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" class="d-flex justify-center" v-if="showErrorForm">
        <div class="d-flex align-center red lighten-5 pa-2 rounded-xl">
          <v-icon color="error" class="mr-2">mdi-alert-circle</v-icon>
          <span class="error--text">
            Completa los campos obligatorios para continuar
          </span>
        </div>
      </v-col>

      <!-- Modal Agregar Variante -->
      <div class="text-center">
        <v-dialog
          v-model="dialogAddVariant"
          max-width="400"
          persistent
          content-class="custom-dialog-content"
        >
          <div class="position-relative">
            <!-- Tarjeta principal del contenido -->
            <v-card
              class="rounded-xl pa-6"
              style="
                background-color: #f4f4fa;
                position: relative;
                overflow: visible;
              "
            >
              <!-- Botón de cierre flotante -->
              <v-btn
                outlined
                color="primary"
                class="py-4 border-3"
                icon
                style="
                  position: absolute;
                  top: -40px;
                  right: 0px;
                  border-radius: 50%;
                  border-width: 3px;
                  background-color: #fff;
                "
                @click="closedialogAddVariant"
              >
                <v-icon color="primary">mdi-close</v-icon>
              </v-btn>

              <v-card-title>
                <h3 class="text-h5 font-weight-bold text-center mb-5">
                  Agregar variante
                </h3>
              </v-card-title>
              <v-card-text>
                <pat-select
                  label="Opción"
                  class="my-5"
                  placeholder="Selecciona una categoría"
                  :items="categoriesFilter"
                  v-model="product.variant.option"
                />
                <pat-input
                  label="Descripción"
                  placeholder="Ej. Jarabe"
                  v-model="product.variant.description"
                  class="mb-5"
                />
                <!-- Mensaje de error -->
                <v-col cols="12" class="d-flex justify-center" v-if="showError">
                  <div
                    class="d-flex align-center red lighten-5 pa-2 rounded-xl"
                  >
                    <v-icon color="error" class="mr-2">mdi-alert-circle</v-icon>
                    <span class="error--text">
                      Completa los campos obligatorios para continuar
                    </span>
                  </div>
                </v-col>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  class="py-6"
                  @click="saveVariant"
                  color="grey primary"
                  dark
                  block
                  rounded
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-dialog>
      </div>

      <!-- Botón de Continuar -->
      <v-card-actions class="justify-center">
        <v-btn
          class="py-6"
          @click="submitForm"
          color="primary"
          dark
          block
          rounded
          >Continuar</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      product: {
        productName: "",
        brand: "",
        selectedCategory: null,
        selectedSubcategory: null,
        controlledProduct: false,
        description: "",
        supplier: "",
        selectedUnitOfSale: null,
        content: "",
        selectedUnitOfMeasurement: null,
        quantity: "",
        BarCode: "",
        sku: "",
        activeSubstances: [],
        noActiveSubstance: false,
        chargeTax: false,
        iva: null,
        ieps: null,
        satKey: "",
        variant: {
          option: "",
          description: "",
        },
      },
      rules: {
        required: (value) => !!value || "Este campo es obligatorio.",
        maxLength: (length) => (value) =>
          !value || value.length <= length || `Máximo ${length} caracteres.`,
        isNumber: (value) =>
          !value || !isNaN(Number(value)) || "Debe ser un número.",
      },
      categoriesFilter: [
        "Todas",
        "Medicamento",
        "Botiquín",
        "Vitaminas",
        "Dermatológico",
        "Alimentos",
        "Belleza",
      ],
      subcategories: ["Subcategoría 1", "Subcategoría 2", "Subcategoría 3"],
      unitsOfSale: ["Unidad 1", "Unidad 2", "Unidad 3"],
      unitsOfMeasurement: ["Gramos", "Kilogramos", "Litros", "Mililitros"],
      unityOptions: ["MG", "ML", "G", "L"],
      ivaOptions: ["16%", "8%", "0%"],
      iepsOptions: ["Selección 1", "Selección 2", "Selección 3"],
      options: ["Tamaño", "Color", "Material"],
      dialogAddVariant: false,
      showError: false,
      showErrorForm: false,
    };
  },
  methods: {
    addActiveSubstance() {
      this.product.activeSubstances.push({
        name: "",
        concentration: "",
        unity: "",
      });
    },
    // Eliminar sustancia activa específica
    removeActiveSubstance(index) {
      this.product.activeSubstances.splice(index, 1);
    },
    addVariant() {
      this.dialogAddVariant = true;
    },
    closedialogAddVariant() {
      this.dialogAddVariant = false;
      this.showError = false;
    },
    saveVariant() {
      const { option, description } = this.product.variant;

      // Verifica si ambos campos están completos
      if (!option || !description) {
        this.showError = true;
        return;
      }

      // Limpia los datos y cierra el diálogo
      this.showError = false;
      this.dialogAddVariant = false;
    },
    submitForm() {
      this.$refs.form.validate();
      if (this.valid) {
        this.showErrorForm = false;
        console.log(this.product);

        // Lógica para enviar datos al servidor
      } else {
        this.showErrorForm = true;
      }
    },
  },
};
</script>
<style>
/* Deshabilita el overflow del modal */
.custom-dialog-content {
  overflow: visible;
}
</style>
